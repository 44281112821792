import React from "react";
import SEO from "../../components/common/seo";
import {graphql, Link} from "gatsby";
import LayoutFourth from "../../components/common/layout-fourth";
import {StaticImage} from "gatsby-plugin-image";
import {OutboundLink} from "gatsby-plugin-google-gtag";

const RequestDemo = ({pageContext, data}) => {
    function initializeHubspotForm() {
        if (typeof window !== 'undefined' && 'hbspt' in window) {
            window.hbspt.forms.create({
                region: "na1",
                portalId: "20249583",
                formId: "e5ff4897-1687-48f4-aad2-50cfb0252cb4",
                target: "#rad-form-container"
            });
        } else {
            setTimeout(initializeHubspotForm, 500)
        }
    }


    initializeHubspotForm();

    return (
        <LayoutFourth pageContext={pageContext} cookies={data.cookies}>
            <SEO lang="es" title="Solicita una demo - Tecalis"/>

            <header id="header" className="rad-header">
                <div className="container" style={{minHeight: 442}}>
                    <StaticImage src={"../../img/brand.png"} alt="Tecalis" width={160} height={39} className="mt-10"/>
                    <h1 className="mt-9">Solicita una Demo</h1>
                    <p className="rad-p">Comprueba por ti mismo todo lo que Tecalis puede hacer por tu negocio.</p>

                    <lottie-player class="rad-animation" src="https://assets6.lottiefiles.com/packages/lf20_zgt2ralf/data.json" background="transparent" speed="1" loop autoplay/>
                </div>
            </header>

            <section className="rad-form-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-12 mb-lg-0 order-lg-6">
                            <div id="rad-form-container"/>
                        </div>

                        <div className="col-lg-6 order-lg-1">
                            <h2>Programa una Demostración Totalmente Personalizada</h2>

                            <p className="rad-p">
                                Rellena el formulario y en menos de 24h uno de nuestros representantes contactará contigo para preparar una demostración acorde a los retos de tu
                                organización.
                            </p>

                            <div align="center" className="logo-wall rad-wall">
                                <img src={require("../../logos/telefonica.png").default} alt="Telefonica"/>
                                <img src={require("../../logos/prosegur.png").default} alt="Prosegur"/>
                                <img src={require("../../logos/claro.png").default} alt="Claro"/>
                                <img src={require("../../logos/vodafone.png").default} alt="Vodafone"/>
                                <img src={require("../../logos/enel.png").default} alt="Enel"/>
                                <img src={require("../../logos/valeo.png").default} alt="Valeo"/>
                                <img src={require("../../logos/orange.png").default} alt="Orange"/>
                            </div>

                            <div className="rad-concept">
                                <h3>¿En qué consistirá la sesión?</h3>

                                <ul>
                                    <li>Una breve charla para identificar las principales necesidades de tu negocio y las de tus usuarios.</li>
                                    <li>Demostración en directo de nuestras soluciones, repasando los principales casos de uso de tu industria y área, así como otros casos de éxito
                                        de nuestros clientes.
                                    </li>
                                    <li>Daremos respuesta a todas tus preguntas para asegurar que cuentas con toda la información necesaria, sin compromiso.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="rad-hero-text">
                <div>Descubre con nuestros expertos</div>
                <div className="text-tecalis">cómo transformar para liderar</div>
            </section>

            <footer className="rad-footer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-12">
                            <StaticImage src={"../../img/brand.png"} alt="Tecalis" width={103} height={25} className="tecalis-logo"/>

                            <p>
                                © 2021 Tecalis. Todos los derechos reservados.<br/>
                                <Link to="/es/legal/condiciones-uso">Condiciones de Uso</Link> | <Link to="/es/legal/privacidad">Aviso de Privacidad</Link>
                            </p>

                            <ul className="list-unstyled list-inline list-social mt-3 mb-6 mb-md-0">
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://www.facebook.com/tecalis/" target="_blank" rel="noopener noreferrer me" className="text-decoration-none">
                                        <i className="fe fe-facebook"/>
                                    </OutboundLink>
                                </li>
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://twitter.com/tecalis" className="text-decoration-none" target="_blank" rel="noopener noreferrer me">
                                        <i className="fe fe-twitter"/>
                                    </OutboundLink>
                                </li>
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://www.linkedin.com/company/tecalis/" className="text-decoration-none" target="_blank" rel="noopener noreferrer me">
                                        <i className="fe fe-linkedin"/>
                                    </OutboundLink>
                                </li>
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://www.instagram.com/tecalis/" target="_blank" rel="noopener noreferrer me" className="text-decoration-none">
                                        <i className="fe fe-instagram"/>
                                    </OutboundLink>
                                </li>

                                <li className="list-inline-item list-social-item">
                                    <OutboundLink href="https://www.youtube.com/c/Tecalis" target="_blank" rel="noopener noreferrer me" className="text-decoration-none">
                                        <i className="fe fe-youtube"/>
                                    </OutboundLink>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-4 d-none d-lg-block text-center">
                            <a href="#header" className="smooth-scroll">
                                <StaticImage src={"../../img/ic_arrow_up.png"} alt="Tecalis" className="mt-6"/>
                                <br/>
                                Volver arriba
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-auto offset-lg-1 offset-md-2">
                            <ul className="iso">
                                <li className="list-inline-item list-social-item mr-1">
                                    <OutboundLink href="https://www.aenor.com/certificacion/certificado/?codigo=199259" rel="noopener noreferrer" target="_blank">
                                        <img src={require("../../img/9001-nuevo.svg").default} width="80" alt="iso9001"/>
                                    </OutboundLink>
                                </li>

                                <li className="list-inline-item list-social-item mr-1">
                                    <OutboundLink href="https://www.aenor.com/certificacion/certificado/?codigo=207780" target="_blank" rel="noreferrer">
                                        <img src={require("../../img/27001-nuevo.svg").default} width="80" alt="iso27001"/>
                                    </OutboundLink>
                                </li>
                            </ul>

                            <ul className="iso">
                                <li className="list-inline-item list-social-item mr-1">
                                    <img src={require("../../img/ic_cer_9001.png").default} width="80" alt="cer9001"/>
                                </li>

                                <li className="list-inline-item list-social-item mr-1">
                                    <img src={require("../../img/ic_eidas.png").default} width="80" alt="eidas"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

        </LayoutFourth>
    )
}

export default RequestDemo

export const requestDemoQuery = graphql
    `query($lang: String) {
    cookies: markdownRemark(
        frontmatter: {
            lang: { eq: $lang }
            name: { eq: "cookies" }
        }
    ) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`;
